import AmountInput from '@/components/input/amount-input/amount-input.vue'
export const fromConfig = (content) => {
  const pickerOptions = {
    disabledDate (v) {
      return v.getTime() <= new Date('2009-12-31') || v.getTime() >= new Date()
    }
  }
  return [
    {
      label: '年份:',
      prop: 'year',
      rules: [{
        required: true,
        message: '请选择年份',
        trigger: ['change', 'blur']
      }],
      attrs: {
        type: 'year',
        placeholder: '请选择',

        'picker-options': pickerOptions
      },

      span: 24,
      tag: 'el-date-picker',
      on: {

      }
    },
    {
      label: '货币资金:',
      prop: 'cashEquivalents',
      rules: [{
        required: true,
        message: '请输入货币资金',
        trigger: ['change', 'blur']
      }],
      attrs: {
        type: '100Y',
        placeholder: '请输入'
      },
      tag: AmountInput,
      on: {

      }
    },
    {
      label: '应收账款:',
      prop: 'accountsReceivable',
      rules: [{
        required: true,
        message: '请输入应收账款',
        trigger: ['change', 'blur']
      }],
      attrs: {
        type: '100Y',
        placeholder: '请输入'
      },
      tag: AmountInput,
      on: {

      }
    }, {
      label: '其他应收账款:',
      prop: 'otherReceivables',
      rules: [{
        required: true,
        message: '请输入其他应收账款',
        trigger: ['change', 'blur']
      }],
      attrs: {
        type: '100Y',
        placeholder: '请输入'
      },
      tag: AmountInput,
      on: {

      }
    },
    {
      label: '预付账款:',
      prop: 'prepayment',
      rules: [{
        required: true,
        message: '请输入预付账款',
        trigger: ['change', 'blur']
      }],
      attrs: {
        type: '100Y',
        placeholder: '请输入'
      },
      tag: AmountInput,
      on: {

      }
    }, {
      label: '总资产合计:',
      prop: 'totalAssets',
      rules: [{
        required: true,
        message: '请输入总资产合计',
        trigger: ['change', 'blur']
      }],
      attrs: {
        type: '100Y',
        placeholder: '请输入'
      },
      tag: AmountInput,
      on: {

      }
    }, {
      label: '总负债合计:',
      prop: 'totalLiability',
      rules: [{
        required: true,
        message: '请输入总负责合计',
        trigger: ['change', 'blur']
      }],
      attrs: {
        type: '100Y',
        placeholder: '请输入'
      },
      tag: AmountInput,
      on: {

      }
    }, {
      label: '主营业务收入:',
      prop: 'primeOperatingRevenue',
      rules: [{
        required: true,
        message: '请输入主营业务收入',
        trigger: ['change', 'blur']
      }],
      attrs: {
        type: '100Y',
        placeholder: '请输入'
      },
      tag: AmountInput,
      on: {

      }
    }, {
      label: '净利润:',
      prop: 'netProfit',
      rules: [{
        required: true,
        message: '请输入净利润',
        trigger: ['change', 'blur']
      }],
      attrs: {
        type: '100Y',
        placeholder: '请输入'
      },
      tag: AmountInput,
      on: {

      }
    }, {
      label: '净利润率(%):',
      prop: 'netProfitRate',
      rules: [{
        required: true,
        message: '请输入净利润率',
        trigger: ['change', 'blur']
      }],
      attrs: {
        type: 'BL',
        placeholder: '请输入'
      },
      tag: AmountInput,
      on: {

      }
    }, {
      label: '资产负债率(%):',
      prop: 'debtAssetRatio',
      rules: [{
        required: true,
        message: '请输入资产负债率',
        trigger: ['change', 'blur']
      }],
      attrs: {
        type: 'BL',
        placeholder: '请输入'
      },
      tag: AmountInput,
      on: {

      }
    }, {
      label: '应收账款周转率(%):',
      prop: 'receivablesTurnoverRatio',
      rules: [{
        required: true,
        message: '请输入应收账款周转率',
        trigger: ['change', 'blur']
      }],
      attrs: {
        type: 'BL',
        placeholder: '请输入'
      },
      tag: AmountInput,
      on: {

      }
    }, {
      label: '现金短债比:',
      prop: 'cashRatio',
      rules: [{
        required: true,
        message: '请输入现金短债比',
        trigger: ['change', 'blur']
      }],
      attrs: {
        type: 'BL',
        placeholder: '请输入'
      },
      tag: AmountInput,
      on: {

      }
    }
  ]
}
export const tableConfig = (content) => {
  return [
    {
      label: '序号',
      prop: 'code',
      width: '50px'
    },
    {
      label: '案号',
      prop: 'caseNumber'
    },
    {
      label: '案由',
      prop: 'causeAction'
    },
    {
      label: '标的金额(元)',
      prop: 'targetAmount'
    },
    {
      label: '案件状态',
      prop: 'caseStatus'
    },
    {
      label: '附件',
      prop: 'file',
      HiddenOverflow: true,
      minWidth: '200px'
    },
    {
      label: '备注',
      prop: 'remark'
    },
    {
      label: '操作',
      prop: 'action',
      isHidden: content.type === 'details'
    }

  ]
}
